<template>
  <div>
     <v-snackbar
      v-model="snackbar"
      color="green"
    >
      
<v-alert type="success">
     Email Sent Successifully
    </v-alert>
     
    </v-snackbar>
    <NavBar />
    <div class="py-4 lg:py-8 relative">
      <img v-bind:src="image" class="
          h-2/5
          lg:h-full
          w-full
          lg:w-1/2
          absolute
          inset-0
          object-cover object-center
          xl:block
          hidden
        " alt="map" />
      <div class="xl:mx-auto xl:container relative">
        <div class="flex flex-wrap xl:mx-auto xl:container">
          <div class="
              w-full
              relative
              lg:w-1/2
              xl:mt-10
              mb-10
              2xl:pr-24 2xl:pl-0
              xl:pl-12
              pl-0
            ">
            <img v-bind:src="image" class="
                h-full
                w-full
                xl:w-1/2
                absolute
                inset-0
                bg-cover bg-center
                xl:hidden
              " alt="map" />
            <div class="
                w-full
                flex flex-col
                items-start
                xl:justify-start
                relative
                z-20
                xl:px-0
                px-4
                xl:py-0
                py-4
              ">
              <div class="w-full 2xl:pl-48 xl:pt-1">
                <h1 class="
                    text-4xl
                    md:text-5xl
                    lg:text-7xl
                    font-bold
                    tracking-wider
                    text-gray-800
                  ">
                  We’re Here
                </h1>
                <div class="w-full md:w-10/12 mt-3">
                  <h2 class="
                      text-gray-800 text-base
                      md:text-lg
                      leading-8
                      tracking-wider
                    ">
                    Your learning needs are unique. So is the way we match you
                    to your tutor!We take great care in selecting tutors we
                    think you’ll love. Every time!
                  </h2>
                  <div class="mt-4 md:mt-8">
                    <h2 class="text-sm md:text-base text-primary font-semibold">
                      Address
                    </h2>
                    <h2 class="
                        text-gray-800 text-base
                        md:text-lg
                        leading-8
                        tracking-wider
                        mt-2
                      ">
                      {{ address }}
                    </h2>
                  </div>
                  <div class="mt-4 md:mt-8">
                    <h2 class="text-sm md:text-base text-primary font-semibold">
                      Contact
                    </h2>
                    <h2 class="
                        text-gray-800 text-base
                        md:text-lg
                        leading-8
                        tracking-wider
                        mt-2
                      ">
                      {{ phone }} (Phone)
                    </h2>
                  
                  </div>
                  <div class="mt-4 md:mt-8">
                    <h2 class="text-sm md:text-base text-primary font-semibold">
                      Email
                    </h2>
                    <h2 class="
                        text-gray-800 text-base
                        md:text-lg
                        leading-8
                        tracking-wider
                        mt-2
                      ">
                      {{ email }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-1/2 xl:pt-10 lg:pl-24">
            <div class="
                flex flex-col
                items-start
                xl:justify-start
                2xl:justify-end
                xl:px-0
                px-4
              ">
              <h1 class="
                  text-4xl
                  md:text-5xl
                  lg:text-7xl
                  font-bold
                  tracking-wider
                  text-primary
                ">
                Let’s Talk
              </h1>
              <div class="w-full 2xl:w-8/12 mt-3">
                <h2 class="
                    text-gray-800 text-base
                    md:text-lg
                    leading-8
                    tracking-wider
                  ">
                  For enquiries, please email us using the form below
                </h2>
                <div class="mt-4 md:mt-8">
                  <p class="text-gray-800 text-base font-medium">Name</p>

                  <v-text-field v-model="emailMessage.name" :error-messages="nameErrors"
                    @input="$v.emailMessage.name.$touch()" @blur="$v.emailMessage.name.$touch()" required
                    value="John Doe" outlined></v-text-field>
                </div>
                <div class="mt-4 md:mt-8">
                  <p class="text-gray-800 text-base font-medium">
                    Email Address
                  </p>
                  <v-text-field v-model="emailMessage.email" :error-messages="emailErrors"
                    @input="$v.emailMessage.email.$touch()" @blur="$v.emailMessage.email.$touch()" required
                    value="Johndoe@gmail.com" outlined>
                  </v-text-field>
                </div>
                <div class="mt-4 md:mt-8">
                  <p class="text-gray-800 text-base font-medium">Message</p>
                  <v-textarea outlined name="input-7-4" v-model="emailMessage.message" :error-messages="messageErrors"
                    @input="$v.emailMessage.message.$touch()" @blur="$v.emailMessage.message.$touch()" required
                    value="Write your message here."></v-textarea>
                </div>
                <div class="py-5">
                  <ul  class="pb-3" v-for="error in errors" :key="errors[error]">
                <li><small class="text-red">
                  {{error}}
                </small>
                </li>
              </ul>
                  <v-btn
        @click="submit()"
          type="submit"
          color="teal"
          dark
          :loading="buttonLoader"
          class="
          loading
            w-full
            text-center
            py-3
            rounded
            bg-yellow-fade
            text-black
            hover:bg-green-dark
            focus:outline-none
            my-1
          "
        >
          Send
        </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import DataService from "@/authentication/data.service";
export default {
  mixins: [validationMixin],
  name: "index",
  components: {
    NavBar,
    Footer,
  },
  validations: {
    emailMessage: {

      email: { required, email },
      name: { required, maxLength: maxLength(50) },
      message: { required, maxLength: maxLength(200) },

    },
  },
  data() {
    return {
      image: "https://cdn.tuk.dev/assets/templates/radian/Back_Image.png",
      address: "Lilongwe, Malawi",
      phone: "+265 982 504 211",
      
      email: "admin@premiumtutorsmw.com",
      snackbar: false,
      text: 'My timeout is set to 2000.',
      timeout: 2000,
      buttonLoader:false,
      errors:"",
      emailMessage: {
        email: "",
        message: "",
        name: "",
      }
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (
        !this.$v.emailMessage.name.$invalid &&
        !this.$v.emailMessage.message.$invalid &&
        !this.$v.emailMessage.email.$invalid
      ) {
        this.buttonLoader =true
        DataService.contactUs(this.emailMessage).then(
          (response) => {
            this.snackbar = true
            this.buttonLoader =false
            console.log(response.data);
          },
          (error) => {
            const errors =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
              this.buttonLoader =false
               if (typeof(errors) === 'string'){
                this.errors = {"errors":errors}
              }else{
                this.errors = errors
              }
              console.log("error: ", errors);
          }
        );
        this.$v.$reset();


      }
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.emailMessage.name.$dirty) return errors;
      !this.$v.emailMessage.name.required && errors.push("Item is required");
      !this.$v.emailMessage.name.maxLength &&
        errors.push("Name must be at most 50 characters long");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.emailMessage.message.$dirty) return errors;
      !this.$v.emailMessage.message.required && errors.push("Item is required");
      !this.$v.emailMessage.message.maxLength &&
        errors.push("Name must be at most 100 characters long");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.emailMessage.email.$dirty) return errors;
      !this.$v.emailMessage.email.email && errors.push("Must be valid e-mail");
      !this.$v.emailMessage.email.required && errors.push("E-mail is required");
      return errors;
    },
  }
};
</script>
